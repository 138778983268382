import React, { useState } from "react"
import classnames from "classnames"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./style.module.css"
import { inject, observer } from "mobx-react";
import { Stars } from "../GoogleReviews"
import Glogo from "./google-icon.svg"
import TimeAgo from 'javascript-time-ago'
// English.
import en from 'javascript-time-ago/locale/en'
import Loader from "../Loader";

TimeAgo.addDefaultLocale(en)

const TrmReview = ({ value, paper }) => (
    <blockquote className={classnames(styles.review, { "paper-low rounded": paper }, "d-flex flex-col justify-content-between")}>
        <Comment>
            {value.comment}
        </Comment>
        <cite style={{marginTop: "1rem"}}><strong>{value.reviewer.displayName}</strong>. Verified user</cite>
    </blockquote >
)

const Comment = ({ children: comment }) => {
    const [expanded, setExpanded] = useState(false)
    // const shortComment = comment.split(" ").slice(0, 20).join(" ")
    const expandable = comment.length > 200
    return (
        <>
            <span className={classnames(styles.comment, { [styles.expandable]: expandable }, { [styles.expanded]: expanded })}>
                {comment}
            </span>
            {(expandable && !expanded) && <button className="text-fatty bg-white d-flex justify-content-center" onClick={() => setExpanded(true)}><span>read more</span></button>}
        </>
    )
}

const GoogleReview = ({ value, paper, hideImage = false }) => (
    <section className={classnames(styles.review, { "paper-low rounded": paper }, "d-flex flex-col justify-content-between")}>
        <header className="text-center">
            {!hideImage && value.reviewer.profilePhotoUrl ? (
                <img width={80} height={80} src={value.reviewer.profilePhotoUrl} alt="" />
            ) : null}
            <cite><strong>{value.reviewer.displayName}</strong></cite>
        </header>
        <div className="d-flex justify-content-center">
            <Stars value={value.rating} />
        </div>
        <blockquote>
            <Comment>
                {value.comment}
            </Comment>
        </blockquote>
        <footer className="d-flex"><img width={24} height={24} src={Glogo} alt="Google Review" /> <span>{new TimeAgo("en-US").format(new Date(value.createTime))}</span></footer>
    </section>
)

const getBackupReviews = () => (
    [
        {
            reviewer: { displayName: "Rosie Slade" },
            comment: "Fernando helped us move from Canary Wharf to Fulham a couple of weeks ago. He turned up early and no job was a trouble. He made our flat move completely stress-free. I would recommend him to anyone looking to move! 5* service.",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Philip Sherar" },
            comment: "A two bedroom flat moved in two hours flat. Brilliant service. Nothing too much trouble. Astonishing value for money. Thanks Guys!",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Cassandra" },
            comment: "The removal team were very helpful, polite and efficient. I would highly recommend their services.",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Lorna Gradden" },
            comment: "Fernando organised a move with multiple pick ups and drop offs across London and Sussex with just a few days notice. He and his colleague were so professional, competent, helpful and reassuring throughout - even when asked to transport a giant, extremely prickly cactus! I am using him again already and have recommended him highly.",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Bruno Menaged" },
            comment: "Fernando is an extremely punctual, attentive and efficient professional. Our change was made without any kind of inconvenience. Highly recommended!",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Cristina Cassina" },
            comment: "Fernando provided us with the best possible removal service. He’s knowledgeable, approachable, tireless and friendly and made what could have been a very demanding day into a stress-free one. Thank you, Fernando!",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Katherine de Gara" },
            comment: "This is the second time we’ve used Fernando and his team and we couldn’t be happier. They make a stressful house move effortless and were so happy for us and friendly. Really professional and know what they’re doing, Fernando really takes pride in his job.",
            platform: "trm"
        },
        {
            reviewer: { displayName: "Fani" },
            comment: "Definitely recommended! Fernando and his colleague were super polite and amazingly fast! They came up with a great idea to fit a sofa from a window even though we had been sure we wouldn't have fitted it! Big thank you!",
            platform: "trm"
        },
    ]
)



const Reviews = inject(stores => ({
    reviews_store: stores.reviews_store
}))(
    observer(({ reviews_store, paper, ...otherProps }) => (
        <div className={classnames({ [styles.plain]: !paper }, { [styles.paper]: paper }, "d-flex align-items-center justify-content-center")}>
            {reviews_store.loaded ? (
                <Slider
                    className={styles.slider}
                    {...{
                        dots: false,
                        arrows: false,
                        vertical: false,
                        infinite: true,
                        autoplay: true,
                        pauseOnHover: false,
                        autoplaySpeed: 4000,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }}
                    {...otherProps}
                >
                    {(reviews_store.error || (reviews_store.reviews?.last_reviews || []).length == 0) ?
                        (
                            getBackupReviews().map((review, i) => <div key={i} className={styles.reviewWrapper}><TrmReview paper={paper} value={review} /></div>)
                        ) :
                        (
                            reviews_store.reviews.last_reviews.filter(r => r.comment).map(r => ({
                                ...r,
                                platform: "google"
                            })).map((review, i) => <div key={i} className={styles.reviewWrapper}><GoogleReview paper={paper} value={review} /></div>)
                        )}
                </Slider>
            ) : <Loader />}

        </div>
    ))
)


export default Reviews